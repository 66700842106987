@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

:root {
  --bg-dark: #3d4d93;
  --bg: #8c9fdb;
  --bg-light: #adc8ff;
  --bg-lighter: #f2f7ff;
  --text-light: #f2f7ff;
  --text-hover: #24327a;
  --border: #cfddff;
  --icon: #073529;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden auto;
  background: var(--bg-lighter);
}

.grid-layout {
  @apply grid grid-cols-1 place-content-center place-items-center gap-[24px];
}

.icon-style {
  height: 100%;
  width: 100%;
  background-color: var(--icon);
  -webkit-mask: url("assets/new-logo.png") no-repeat right/100% 150%;
}
.icon {
  @apply mx-[8px];
}

.gradient-light {
  background: linear-gradient(to bottom, var(--bg), var(--bg-lighter));
}

.gradient-dark {
  background: linear-gradient(to top, var(--bg-dark), var(--bg-light));
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--border);
}

@media screen and (max-width: 750px) {
  .slider {
    display: grid;
    grid-template-columns: repeat(5, 100vw);
    animation: slide 20s steps(4) reverse infinite;
  }
}

@media screen and (min-width: 750px) {
  .slider {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}
@keyframes slide {
  0% {
    transform: translateX(-200vw);
  }
  50% {
    transform: translateX(200vw);
  }
  100% {
    transform: translateX(-200vw);
  }
}
.whatsapp-float {
  background-color: #25d366;
  color: var(--text-light);
  font-size: 50px !important;
  z-index: 1000;
  padding: 8px;
  border-radius: 100%;
}
